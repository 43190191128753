import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Injectable({
    providedIn: 'root'
})
export class MsalAuthGuard implements CanActivate {

    constructor(private authService: MsalService, private router: Router) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        const isAuthenticated = this.authService.instance.getAllAccounts().length > 0;
        if (!isAuthenticated) {
            this.router.navigate(['/signin']);
        }
        return isAuthenticated;
    }
}